@use "../base/variable" as *;
@use "../base/theme_orange" as *;
@use "../base/icomoon-style";
@use "../base/mixin" as *;
@use "../base/keyframes";


// common styles
.bg_bc_w {
  background-color: $bc_white;
}
.bg_bc_primary {
  background-color: $bc_primary;
}
.bg_bc_primary_light {
  background-color: $bc_primary_light;
}
.w-full {
  width: 100%;
}
.w-extraWide {
  max-width: 1920px;
  margin: auto;
}
.w-wide {
  width: calc(100% - 80px);
  max-width: 1286px;
  margin: auto;
  @include media(tab) {
    width: calc(100% - 20px);
  }
}
.w-middle {
  width: calc(100% - 40px);
  max-width: 1166px;
  margin: auto;
  @include media(tab) {
    width: calc(100% - 20px);
  }
}

.l-breadcrumbList {
  max-width: 1166px;
  width: calc(100% - 40px);
  margin: 0 auto 3.125em;
  overflow: auto;
  @include media(tab) {
    padding-top: 15px;
    margin-bottom: 1em;
  }
  @include media(sp) {
    padding-top: 15px;
  }
  &__list {
    display: flex;
    align-items: center;
    padding: 1em 0;
    line-height: 1.3;
    white-space: nowrap;
    @include media(tab) {
      padding: 0 0 .3em;
    }
  }
  &__item {
    &:nth-of-type(n+2) {
      &::before {
        font-size: 0.5em;
        font-weight: bold;
        padding: 0 1em;
        content: "\e902";
        font-family: "icomoon-lc-casual";
      }
    }
  }
}
.column {
  display: grid;
  &__item {
    &:hover {
      a {
        opacity: 1;
        img {
          opacity: 1;
        }
      }
      .figure {
        opacity: 1;
        border: solid 1px $bc_primary;
      }
      .name {
        opacity: 1;
      }
    }
  }  
  .column__item{
    display: flex;
    flex-direction: column;
  }
  .figure {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-top: calc(100% - 2px);
    background-color: $bc_white;
    border: solid 1px transparent;
  }
  .figureBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25em;
  }  
  .height_parent{
    flex-grow: 1;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .name {
    margin: 1em 0 0;
    font-size: 1.8rem;
    line-height: 1.55;    
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    @include media (tab) {
      font-size: 1.4rem;
      line-height: 1.3;
    }
  }
  .price {
    display: flex;
    margin-bottom: 1em;
    align-items: baseline;
    letter-spacing: .1em;    
    .only-sp{
      display: none;
    }
    @include media (tab) {
      font-size: 1.4rem;
      display: block;
      .only-sp{
        display: block;
      }
    }
  }
  .sale_price{
    margin-bottom: 0;
    color: red;
    span{
      color: #2c2c2c;
    }
  }
  .original_price{
    text-decoration: line-through;
  }
  .amount {
    font-size: 3rem;
    @include media (tab) {
      font-size: 2rem;
    }
  }
  .currency {
    padding-left: 0.1em;
    font-size: 1.6rem;
  }
  .addToCart {
    margin-bottom: 5em;
    @include media (tab) {
      margin-bottom: 2em;
    }
    a,
    button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      background-color: $bc_white;
      border: solid 1px $bc_primary;
      color: $bc_primary;
      @include media (tab) {
        height: 40px;
      }
      &::after {
        content: "\e904";
        position: absolute;
        top: 38%;
        right: 1em;
        font-family: 'icomoon-lc-casual';
        font-size: 1.4rem;
        line-height: 1;
        display: none;
      }
      &:hover {
        opacity: 1;
        background-color: $bc_primary;
        color: $bc_white;
      }
      &:disabled{
        cursor: not-allowed;
        background: #c2c2c2;
        color: #fff;
        border: 1px solid #c2c2c2;
        &::after {
          display: none;
        }
      }
    }    
  }
}
.seeMore {
  display: flex;
  justify-content: center;
  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 70px;
    border-radius: 45px;
    border: solid 1px $bc_primary;
    color: $bc_primary;
    background-color: $bc_white;
    box-shadow: 5px 6px 0 $bc_primary;
    transition: all 0s;
    @include media(sp) {
      width: 300px;
      height: 50px;
    }
    &:hover {
      top: 1px;
      left: 1px;
      box-shadow: 0 0 0 $bc_primary;
      background-color: $bc_primary;
      color: $bc_white;
      opacity: 1;
    }
    span {
      font-size: 2.4rem;
      font-weight: 500;
      display: inline-block;
      line-height: 1;
      @include media(sp) {
        font-size: 1.8rem;
      }
    }
  }
}

// top page
.l-hero {
  &__item {
    height: auto;
    padding: 0 1%;
    @include media(tab){
      height: 200px;
    }
    a {
      display: block;
      height: 100%;
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}
.slick-prev,
.slick-next {
  width: 70px;
  height: 90px;
  background-color: rgba(255,255,255,0.8);
  text-align: center;
  @include media(tab) {
    width: 35px;
    height: 45px;
  }
  &::before {
    content: "\e904";
    display: inline-block;
    width: 2.8rem;
    height: 2.8rem;
    font-size: 2.8rem;
    font-family: 'icomoon-lc-casual';
    color: $bc_primary;
    @include media(tab) {
      width: 2rem;
      height: 2rem;
      font-size: 2rem;
    }
  }
  &:hover,
  &:focus {
    background-color: rgba(255,255,255,0.8);
  }
}
.slick-prev {
  left: 0;
  border-radius: 0 45px 45px 0;
  &::before {
    transform: rotate(180deg);
  }
}
.slick-next {
  right: 0;
  border-radius: 45px 0 0 45px;
}
.l-contents {
  .bg_bc_w & {
    padding: 7.5em 0;
    @include media(tab) {
      padding: 60px 0;
    }
    @include media(sp) {
      padding: 40px 0;
    }
  }
  .bg_bc_w &.pd_pickupItems {
    padding-top: 3em;
    @include media(tab) {
      padding-top: 30px;
    }
    @include media(sp) {
      padding-top: 20px;
    }
  }
  .bg_bc_primary_light & {
    padding: 5em 0;
    @include media(sp) {
      padding: 2em 0;
    }
  }
  .bg_bc_primary_light + .bg_bc_primary_light & {
    padding-top: 2.5em;
  }
  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: 1.58em;
    font-size: 3.8rem;
    background: url(../images/bg_lines.svg) repeat-x;
    background-position: center 55%;
    @include media(tab) {
      font-size: 2.4rem;
      margin-bottom: 40px;
    }
    @include media(sp) {
      margin-bottom: 20px;
    }
    span {
      padding: 0 1em;
      display: block;
    }
  }
}
.banners {
  .column {
    column-gap: 2.3%;
    grid-template-columns: repeat(3, minmax(31%, 1fr));
    margin-bottom: 4vw;
    @include media(sp) {
      grid-template-columns: repeat(2, minmax(48%, 1fr));
    }
    &__item {
      a {
        display: block;
        margin-bottom: 2em;
      }
      &:hover {
        a {
          box-shadow: 2px 2px 5px rgba(0,0,0,.5);
        }
      }
    }
  }
}
.newItems,
.pickupItems {
  .column {
    column-gap: 2.3%;
    grid-template-columns: repeat(5, minmax(18%, 1fr));
    @include media(lg) {
      grid-template-columns: repeat(3, minmax(30%, 1fr));
    }
    @include media(sp) {
      grid-template-columns: repeat(2, minmax(48%, 1fr));
    }
  }
}
.rankingItems {
  .column {
    column-gap: 2.3%;
    grid-template-columns: repeat(4, minmax(23%, 1fr));
    @include media(lg) {
      grid-template-columns: repeat(3, minmax(30%, 1fr));
    }
    @include media(sp) {
      grid-template-columns: repeat(2, minmax(48%, 1fr));
    }
    &__item {
      &:nth-of-type(1) .figure::before {
        background-image: url(../images/icon_ranking_01.svg);
      }
      &:nth-of-type(2) .figure::before {
        background-image: url(../images/icon_ranking_02.svg);
      }
      &:nth-of-type(3) .figure::before {
        background-image: url(../images/icon_ranking_03.svg);
      }
      &:nth-of-type(4) .figure::before {
        background-image: url(../images/icon_ranking_04.svg);
      }
      &:nth-of-type(5) .figure::before {
        background-image: url(../images/icon_ranking_05.svg);
      }
      &:nth-of-type(6) .figure::before {
        background-image: url(../images/icon_ranking_06.svg);
      }
      &:nth-of-type(7) .figure::before {
        background-image: url(../images/icon_ranking_07.svg);
      }
      &:nth-of-type(8) .figure::before {
        background-image: url(../images/icon_ranking_08.svg);
      }
    }
    .figure {
      &::before {
        position: absolute;
        top: 5px;
        left: 5px;
        content: "";
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        z-index: 1;
      }
    }
  }
}
// archive page
.l-contentsWrap {
  display: grid;
  column-gap: 1fr;
  grid-template-columns: 1fr 5fr;
  width: 1160px;
  max-width: 100%;
  @include media(tab) {
    display: block;
  }
}
.archive {
  &__topArea {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 3.75em;
    @include media(sp) {
      margin-bottom: 2em;
    }
  }
  &__title {
    margin-right: .5em;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: .05em;
    display: inline-block;
    line-height: 1;
    @include media(sp) {
      font-size: 2.4rem;
    }
  }
  .number {
    font-size: 1.4rem;
    color: $bc_text_gray;
    line-height: 1.3;
  }
  .column {
    column-gap: 2.3%;
    grid-template-columns: repeat(4, minmax(23%, 1fr));
    @include media(lg){
      grid-template-columns: repeat(3, minmax(30%, 1fr));
    }
    @include media(sp) {
      grid-template-columns: repeat(2, minmax(140px, 1fr));
    }
    .figure {
      &::before {
        position: absolute;
        top: 5px;
        left: 5px;
        content: "";
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        z-index: 1;
      }
    }
  }
}
.controller {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 60px 0;
  @include media (1024px) {
    flex-flow: column;
    align-items: flex-start;
    margin: 30px 0;
  }

  &__navi {
    @include media (1024px) {
      margin-bottom: 10px;
    }
  }

  &__list, &__item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 2em;
    }
  }
  &__label {
    margin-right: .2em;
  }
  &__opt {
    select {
      border: none;
      border-bottom: solid 1px $bc_boder_gray;
      padding: .2em .5em;
      background-color: $bc_white;
      color: #000;
      border-radius: 0;
      &:active,
      &:focus {
        outline: 0;
      }
    }
  }
}

.noStock {
  color: red;
}

// pager
.pager {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 60px 0;
  @include media (sp) {
    margin: 30px 0;
  }
  a, p, strong {
    display: inline-block;
    width: 30px;
    height: 60px;
    line-height: 60px;
    @include media (sp) {
      width: 26px;
      height: 26px;
      height: 30px;
      line-height: 30px;
    }
  }
  a {
    margin: 0 8px;
    &:first-of-type,
    &:last-of-type {
      width: 60px;
      height: 60px;
      @include media (sp) {
        width: 30px;
        height: 30px;
        margin: 0 3px;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border: solid 1px $bc_primary;
        border-radius: 100%;
        color: $bc_primary;
        line-height: 1;
        font-size: 1.6rem;
        font-weight: 600;
        @include media (sp) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  strong {
    &.current {
      color: $bc_primary;
      border-radius: 8px;
    }
  }
}


//cart in
.l-cartInModal {
  display: none;
  &.is-active {
    position: fixed;
    top: 0;
    left: 0;
    padding: 3rem;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 99;
    text-align: center;
    display: flex;
    @include media(tab){
      padding: 20px 15px;
    }
  }
  &__bg,
  &__body {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    .is-active > & {
      opacity: 1;
      visibility: visible;
      padding: 80px 60px;
      @media screen and (max-width: 800px) {
        padding: 60px 30px;
      }
      @include media(tab){
        padding: 30px 20px;
      }
    }
  }
  &__bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11;
    cursor: pointer;
  }
  &__body {
    width: 100%;
    max-width: 819px;
    padding: 0;
    background: #fff;
    z-index: 12;
    border-radius: 10px;
    position: relative;
    max-height: 80vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .cartIn-btn .btns{
    width: 100%;
    .cartin{
      margin-right: 0;
    }
  }
}

.cartInArea{
  position: relative;
}
.l-cartin {
  margin: 0 auto;
  &Sku {
    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      &::after {
        content: "";
        display: block;
        flex-grow: 1;
        margin-left: 7px;
        border-top: solid 1px $bc_boder_gray_light;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      min-width: 30%;
      margin-right: 10px;
      margin-bottom: 0;
      position: relative;
      margin-bottom: 10px;
      &--image {
        width: 30%;
      }
      &:nth-child(8n) {
        margin-right: 0;
      }
      .l-cartinSku__list--text > & {
        width: auto;
      }
    }
    &__radio {
      position: absolute;
      opacity: 0.01;
      &:disabled ~ * {
        opacity: 0.33;
      }
    }
    &__figure {
      //padding-bottom: 100%;
      padding: 12px 0;
      margin-bottom: 5px;
      border: solid 1px #cbcbcb;
      position: relative;
      transition: 0.3s;
      :checked + & {
        background: #cbcbcb;
        color: #000;
      }
      > img {
        display: none;
        max-width: none;
        width: 90%;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        &.is-portrait {
          width: auto;
          height: 90%;
        }
      }
      &.is-loaded {
        > img {
          opacity: 1;
          transition: 0.3s;
        }
      }
    }
    &__text {
      font-size: 100%;
      hyphens: auto;
      line-height: 1.3;
      text-align: center;
      word-break: break-word;
      :checked + & {
        box-shadow: 0 0 0 3px $c_text;
      }
    }
    &__button {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 45px;
      min-height: 45px;
      padding: 0 10px;
      border: solid 1px #cbcbcb;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      transition: 0.3s;
      :checked + & {
        background: #cbcbcb;
        color: #000;
      }
    }
  }
  &Quantity {
    display: flex;
    align-items: center;
    margin: 26px 0 0 0;
    @include media(1160px) {
      display: block;
      width: 100%;
    }
    &__label {
      font-size: 1.6rem;
      margin-right: 1em;
    }
    &__form {
      //display: flex;
    }
    &__input {
      &,
      &[type="text"] {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 40px;
        padding: 0 5px;
        border: solid 1px $c_border;
        border-radius: 0;
        font-size: 1.6rem;
        line-height: 1;
        text-align: center;
      }
    }
    &__button {
      &--minus,
      &--plus {
        @extend .l-cartinQuantity__input;
        width: 40px;
      }
      &--minus {
        border-right: none;
      }
      &--plus {
        border-left: none;
      }
    }
  }
  &Option {
    font-size: 1.6rem;
    &__item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 15px;
      margin-bottom: 15px;
      background: #EFEFEF;
      &--embroidery {
        display: none;
        background: #FAFAFA;
        margin-bottom: 0;
        .l-cartinOption__item:not(.l-cartinOption__item--embroidery) + & {
          margin-top: -15px;
        }
        &.is-active {
          display: flex;
        }
      }    
    }
    &__label {
      width: 120px;
    }
    &__note {
      width: 100%;
      margin-top: 11px;
      font-size: 1.4rem;
    }
    &__pagenote {
      width: 100%;
      margin: 11px 0 30px;
      font-size: 1.4rem;
    }
    &__form {
      flex-grow: 1;
      max-width: calc(100% - 120px);
    }
    &__select {
      width: 100%;
      height: 40px;
      padding: 0 5px;
      border: none;
    }
  }
  .productActoins {
    // &__cartin {
    //   background-color: #000;
    //   border-radius: 5px;
    //   color: #fff;
    //   font-size: 16px;
    //   width: 200px;
    //   padding: 10px 30px;
    //   line-height: 25px;
    //   &:hover,
    //   &:active {
    //     opacity: .8;
    //   }
    // }
    &__favorite {
      margin-left: 20px;
      font-size: 5rem;
    }
  }
}
.close-button-div{  
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 32px;
  height: 32px;
  @include media(tab){
    top: 2rem;
    right: 2rem;
    width: 30px;
    height: 30px;
  }
  .close-button{
    background: none;
    position: relative;
    width: 32px;
    height: 32px;
    @include media(tab){
      width: 28px;
      height: 28px;
    }
    &::after,
    &::before{
      content: "";
      position: absolute;
      top: 1rem;
      right: 0;
      height: 1px;
      background: #000;
      border-radius: 1px;
      transform: rotate(-45deg);
      width: 32px;
      @include media(tab){
        width: 28px;
      }
    }
    &::before{
      transform: rotate(45deg);
    }
}

}
.productDetail{
  .detail_text,
  .default_image{
    display: none;
  }
}
.modal_body{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include media(sp){
    display: block;
    text-align: center;
    .l-cartinQuantity__form{
      text-align: left;
    }
  }
  .default_image{
    width: 50%;
    text-align: left;
    @include media(tab){     
      width: 100%;
      margin: 20px auto 0 auto;
      text-align: center;
    }
    img{
      width: 80%;
      height: auto;
      @include media(tab){
        width: 50%;
      }
    }
  }
  .product_detail{
    width: 50%;
    @include media(tab){
      width: 100%;
    }
    .parent_price{
      border-bottom: solid 1px $c_border;      
      padding-bottom: 15px;
      margin: 40px 0;
      @include media(tab){
        margin: 10px 0;
        padding-bottom: 10px;
      }
    }
  }
  .detail_text{
    margin-top: 15px;
    text-align: right;
    max-width: 322px;
    a{
      display: inline-block;
      padding: 0 0 3px 0;
      border-bottom: 1px solid $c_text;
    }
    @media (max-width: 1160px){
      max-width: 100%;
    }
  }
  .productMainSection__title{
    text-align: left;
  }
}
.productDetail{
  .modal_body{
    @media (max-width: 1160px){
      display: block;
    }
    .l-cartinQuantity{
      margin-bottom: 0;
    }
  }
}


.cartInArea .product_detail{
  width: 100%;
}

.productMainSection .product_detail{
  @media (max-width: 1160px){
    width: 100%;
  }
}

.productMainSection {
  &__header {
    .productMainSection & {
      float: right;
      width: calc(100% - 700px);
      @include media(tab) {
        float: none;
        width: auto;
      }
    }
  }
  &__title {
    margin-bottom: 7px;
    font-size: 2.8rem;
    line-height: 1.25;
    font-weight: bold;
  }
  &__code {
    margin-bottom: 10px;
    color: $c_text_gray;
    font-size: 1.4rem;
    line-height: 1;
  }
  &__desc {
    margin-bottom: 10px;
    .productMainSection & {
      float: right;
      width: calc(100% - 700px);
      @include media(tab) {
        float: none;
        width: auto;
      }
    }
  }
  &__info {
    display: flex;    
    gap: 15px;
    .productMainSection & {
      float: right;
      width: calc(100% - 700px);
      @include media(tab) {
        float: none;
        width: auto;
      }
    }
  }  
  &__price {
    font-size: 2.4rem;
    line-height: 1;
    small {
      font-size: 1.4rem;
      margin-left: 0.5rem;
      display: inline-block;
      color: $c_text;
    }
    &--small {
      font-size: 1.2rem;
      margin-bottom: 20px;
      .productMainSection & {
        float: right;
        width: calc(100% - 700px);
        @include media(tab) {
          float: none;
          width: auto;
        }
      }
      @include media(tab) {
        margin-bottom: 5px;
        position: relative;
        top: -10px;
      }
    }
  }
  + .contentsInner {
    margin-top: 0;
  }
  @include media(1280px) {
    padding: 40px 60px 60px;
  }
  @include media(1024px) {
    padding: 40px 15px 30px;
    &__header {
      width: auto;
    }
    &__info {
      width: 315px;
    }
    &__desc {
      width: 315px;
    }
    &__price {
      width: auto;
      &--small {
        width: 315px;
      }
    }
  }
  @include media(tab) {
    &__header {
      margin-top: 30px;
    }
    &__title {
      margin-bottom: 10px;
      margin-top: 5px;
      font-size: 2rem;
      line-height: 1.33;
    }
    &__code {
      margin-bottom: 15px;
      font-size: 1.2rem;
    }
    &__info {
      width: auto;
      // padding: 20px 0 5px;
    }
    &__desc {
      width: auto;
    }
    &__price {
      // padding-bottom: 12px;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 2rem;
      > small {
        font-size: 1.6rem;
      }
    }
    ~ .contentsInner {
      padding-left: 15px;
      padding-right: 15px;
      &.lc-reviewsArchive {
        padding: 20px 15px;
      }
    }
  }
  &__info.original_price_div{
    text-decoration: line-through;
    margin-top: 10px;
    .productMainSection__price{
      font-size: 1.4rem;
      small{
        margin-left: 0;
        display: initial;
      }
    }    
  }
}
.productImages {
  float: left;
  width: 640px;
  &__main {
    &__inner {
      display: block;
      padding-bottom: 100%;
      background: #EFEFEF;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      > img {
        width: auto !important;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.cartInArea {
  .productMainSection & {
    //float: right;
    @include media(tab) {
      float: none;
      width: auto;
    }
  }
  &__actions {
    display: flex;
    .lc-button {
      &--action {
        flex-grow: 1;
      }
      &--onlyIcon {
        flex-grow: 0;
        margin-left: 15px;
      }
    }
  }
  @include media(tab) {
    &__actions {
      margin-top: 20px;
    }
  }
}
.skuList {
  line-height: 1.25;
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-direction: column;
  }
  &__label {
    flex-shrink: 0;
    width: 100%;
    font-size: 1.6rem;
    position: relative;
    margin-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      top: 11px;
      left: 0;
      background-color: green;
      opacity: .5;
    }
    span {
      background-color: pink;
      z-index: 1;
      position: relative;
      padding-right: 10px;
    }
  }
  &__detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &--size {
      justify-content: flex-start;
      margin: 0 auto 0 0;
    }
  }
  &__radio {
    display: block;
    margin: 5px 0px 5px 0;
    position: relative;
    width: calc(12.5% - 6px);
    &--size {
      width: 50px;
      height: 50px;
      border: solid 1px #707070;
      margin-right: 8px;
    }
    &__input {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      opacity: 0.01;
      &:checked + .skuList__radio__img {
        padding: 2px 2px;
        border: solid 2px #000;
        color: #000;
      }
      &:disabled + .skuList__radio__img {
        border: solid 1px $c_border;
        background: gray;
        color: $c_text_gray;
        cursor: default;
      }
    }
    &__img {
      padding: 2px 2px;
      border-radius: 1px;
      border: solid 1px $c_text_gray;
      transition: 0.3s;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 32px;
      height: 32px;
      padding: 0 7px;
      font-size: 1rem;
      border-radius: 100px;
      &--size {
        height: 50px;
        font-size: 1.6rem;
      }
    }
  }
  .lc-selectBox__select {
    width: auto;
  }
  @include media(tab) {
    &__item {
      margin-bottom: 10px;
    }
    &__label {
      width: 75px;
    }
    &__radio {
      margin: 5px 10px 5px 0;
    }
  }
}
.additionsForm {
  padding: 30px;
  background: #EFEFEF;
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: $c_text_gray;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    &::before {
      display: inline-block;
      margin-right: 5px;
      font-size: 2.4rem;
    }
  }
  &__item {
    margin-bottom: 15px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.01;
    &:checked + .additionsForm__box {
      border-color: #000;
      .additionsForm {
        &__checkbox {
          border-color: #000;
          &::before {
            opacity: 1;
            color: #000;
          }
        }
      }
    }
  }
  &__box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    //background: $c_base;
    border: solid 1px $c_border;
    transition: 0.3s;
    user-select: none;
  }
  &__name {
    display: flex;
    align-items: center;
    line-height: 1.25;
  }
  &__checkbox {
    display: block;
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    border: solid 1px $c_text;
    position: relative;
    transition: 0.3s;
    // @extend .lc-icon;
    // @extend .lc-icon--check;
    &::before {
      opacity: 0;
      transition: 0.3s;
      position: absolute;
      left: -1px;
      top: -1px;
    }
  }
  &__detail {
    width: 100%;
    margin-top: 5px;
    padding-top: 7px;
    border-top: dotted 1px $c_border;
    font-size: 1.4rem;
    line-height: 1.5;
  }
  @include media(tab) {
    padding: 15px;
    &__title {
      margin-bottom: 7px;
      font-size: 1.8rem;
    }
    &__item {
      margin-bottom: 10px;
    }
    &__detail {
      width: 100%;
      margin-top: 7px;
      padding-top: 10px;
      font-size: 1.2rem;
    }
  }
}
.contentsInner {
  padding: 0 calc(50vw - 580px) 0px;
  section {
    padding: 0 20px 0 194px;
    @include media(tab) {
      padding: 0;
    }
  }
  h2 {
    border-top: solid 1px #707070;
    font-size: 2rem;
    margin-bottom: 30px;
    @include media(tab) {
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    span {
      background-color: #707070;
      color: pink;
      padding: 15px 25px;
      position: relative;
      border-radius: 0 0 20px 0;
      width: 130px;
      display: flex;
      justify-content: center;
      @include media(tab) {
        padding: 2px 10px;
        position: relative;
        border-radius: 0 0 15px 0;
        width: 80px;
      }
    }
  }
  h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
    @include media(tab) {
      font-size: 1.4rem;
    }
  }
  p {
    font-size: 1.4rem;
    @include media(tab) {
      font-size: 1.2rem;
    }
  }
  ol {
    display: flex;
    margin-top: 30px;
    > li:not([class])  {
      margin-right: 60px;
      @include media(tab) {
        margin-right: 0;
      }
    }
    @include media(tab) {
      flex-direction: column;
      margin-top: 20px;
    }
  }
  ul {
    > li:not([class]) {
      list-style-type: disc;
      margin-left: 20px;
    }
  }
}

.reviewsArchive {
  &__number {
    font-size: 1.8rem;
    position: relative;
    top: -4px;
    margin-left: 10px;
  }
}
.reviewStars {
  display: flex;
  width: 6em;
  font-size: 1.4rem;
  @include media(tab-min) {
    &.is-changing {
      cursor: pointer;
      > span {
        filter: drop-shadow(0 3px 0 rgba(0, 0, 0, 0.25));
      }
    }
  }
  &--large {
    font-size: 2.1rem;
    + .reviewStars__number {
      font-size: 2rem;
    }
  }
  &__product {
    margin: 0 0 0 auto !important;
    top: 1px;
    position: relative;
    font-size: 2rem !important;
  }
  > span {
    display: block;
    margin-right: 0.25em;
    width: 1em;
    height: 1em;
    background-image: url(../img/icons/review-star-empty.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    &:last-child {
      margin-right: 0;
    }
  }
  
  &__number {
    margin-left: 10px;
    font-weight: bold;
  }
  @include media(tab) {
    &--large {
      font-size: 1.9rem;
      + .reviewStars__number {
        font-size: 1.8rem;
      }
    }
  }
}

.news_01,
.news_detail{
  max-width: 986px;
  .news_title{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    h1{
      font-size: 3.8rem;
      font-weight: bold;
    }
    span{
      font-size: 16px;
      margin-left: 15px;
      display: inline-block;
    }
    .see_all{
      position: relative;
      display: inline-block;
      padding-right: 25px;
      &::after{
        content: "\e904";
        position: absolute;
        top: 50%;
        right: 0;
        font-family: "icomoon-lc-casual";
        font-size: 1.4rem;
        line-height: 1;
        transform: translateY(-50%);
      }
    }
  }
  .news_list{
    margin-top: 35px;
    @include media(tab){
      margin-top: 20px;
    }
    li .newPage{      
      display: flex;
      padding: 30px 0;
      border-bottom: solid 1px #bababa;
      @include media(tab){
        flex-wrap: wrap;
      }
      .date{
        min-width: 180px;
        padding-right: 15px;
        font-size: 14px;
        @include media(tab){
          width: 100%;
          margin-bottom: 15px;
        }
      }
      .news_info{
        font-size: 16px;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 72px;
        overflow: hidden;
      }
    }
  }  
  .news_type{
    font-size: 12px;
    background: #ec6c01;
    border-radius: 2px;
    padding: 0 4px;
    color: #fff;
    line-height: 1;
    margin-left: 10px;
    &.pink{
      background: #E56B8C;
    }
    &.blue{
      background: #51A5D9;
    }
  }
  time{
    font-size: 14px;
  }
}


.news_detail{
  .l-contentsWrap{
    display: flex;
    justify-content: space-between;
    .archive{
      width: 100%;
      .news_detail_title{
        font-size: 2.6rem;
        margin: 20px 0 50px 0;
        font-weight: bold;
      }
      p{
        font-size: 1.6rem;
        line-height: 1.5;
        margin: 0 0 90px 0;
      }
      h1{
        font-size: 2.2rem;
        margin: 0 0 40px 0;
        font-weight: bold;
        border-bottom: 2px solid $bc_primary;
        padding-bottom: 15px;
      }
      h2{
        font-size: 1.8rem;
        font-weight: bold;
        padding-bottom: 15px;
        margin: 0 0 40px 0;
        position: relative;
        &::after{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-image: linear-gradient(to right, $bc_primary 50%, #BFBFBF 50%);          
        }
      }
      h3{
        font-size: 1.8rem;
        font-weight: bold;
        border-bottom: 1px solid #BFBFBF;
        padding-bottom: 15px;
        margin: 0 0 40px 0;
      }
    }
    .sidebar{
      margin-right: 0;
    }
  }
  
}
